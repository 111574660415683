import React from "react";

//login-root
const Login = React.lazy(() => import("./pages/ModulosBase/Login/Login"));
//mapa
const Mapa = React.lazy(() => import("./pages/ModulosBase/Mapa/MapaPruebaPLC"));

//Dashboards
//dashboard
const Dashboard = React.lazy(() =>
  import("./pages/ModulosBase/Dashboard/overview/Overview")
);
//Graficas
const Graficas = React.lazy(() =>
  import("./pages/ModulosBase/Dashboard/graficas/Graficas")
);
//Lecturas
const Lecturas = React.lazy(() =>
  import("./pages/ModulosBase/Dashboard/lecturas/Lecturas")
);
//Información
const Informacion = React.lazy(() =>
  import("./pages/ModulosBase/Dashboard/informacion/Informacion")
);
//admin
const AdminSitios = React.lazy(() =>
  import("./pages/ModulosBase/Admin/AdminSitios")
);
const AdminUsuarios = React.lazy(() =>
  import("./pages/ModulosBase/Admin/AdminUsuarios")
);
//create routes
//Modulos Especiales
const UploadCSV = React.lazy(() =>
  import("./pages/ModulosEspeciales/UploadCSV/UploadCSV")
);

const ReporteZonas = React.lazy(() =>
  import("./pages/ModulosEspeciales/ReportesP/ReporteZonas")
);

const ReporteZonasA = React.lazy(() =>
  import("./pages/ModulosEspeciales/ReportesP/ReporteZonasAuto")
);

const ReporteCalidad = React.lazy(() =>
  import("./pages/ModulosEspeciales/ReportesP/ReporteCalidad")
);

const Comparador = React.lazy(() =>
  import("./pages/ModulosEspeciales/Comparador/Comparador")
);
const GenLlav = React.lazy(() => import("./pages/ModulosBase/Admin/GenKeys"));
/*const ReporBitacora = React.lazy(() =>
  import("./pages/ModulosEspeciales/Bitacora/Bitacora")
);*/

const routes = [
  //rutas con layout
  { path: "/mod/reporteza", isWithoutLayout: true, component: ReporteZonasA },
  { path: "/mod/reportec", component: ReporteCalidad },
  { path: "/mod/reportez", component: ReporteZonas },
  { path: "/mod/csvupload", component: UploadCSV },
  { path: "/mod/comparador", component: Comparador },
 // { path: "/mod/bitacora", component: ReporBitacora },
  { path: "/dashboard/overview", component: Dashboard },
  { path: "/dashboard/graficas", component: Graficas },
  { path: "/dashboard/lecturas", component: Lecturas },
  { path: "/dashboard/informacion", component: Informacion },
  { path: "/admin/sitios", component: AdminSitios },
  { path: "/admin/usuarios", component: AdminUsuarios },
  { path: "/admin/genlla", component: GenLlav },

  //rutas sin layout
  { path: "/mapa", component: Mapa, isWithoutLayout: true },
  { path: "/admin", component: Login, isWithoutLayout: true },

  { path: "/", component: Login, isWithoutLayout: true, exact: true }
];

export default routes;
